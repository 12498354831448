import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { Markdown } from '@/components/common/Markdown'
import { PageContainer } from '@/components/common/PageContainer'
import { PageCover } from '@/components/common/PageCover'
import { PageTitle } from '@/components/common/PageTitle'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "impressum" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    impressum: strapiImpressum {
      content
    }
  }
`

const ImpressumPage: FC = () => {
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)
  return (
    <>
      <SEO title="Impressum" />
      <PageCover image={image} title="Impressum" />
      <PageContainer background="default">
        <PageTitle>Impressum</PageTitle>
        <Markdown>{data.impressum.content}</Markdown>
      </PageContainer>
    </>
  )
}

export default ImpressumPage
